import React, { Component, Fragment } from 'react'
import { Button, Input, Typography, Statistic, message, Tag, List, DatePicker } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import history from '../../history'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import OrdersDeltaTable from './OrdersDeltaTable'

import { actions as OrdersActions } from '../../store/OrdersReducer'
import { actions as SMSActions } from '../../store/SMSReducer'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(utc)
dayjs.extend(timezone)

const { Title } = Typography

const calcPositions = (positions) => {
  return positions.map((p) => {
    const priceSale = p.price
    const priceDzen = p.assortment.salePrices.find(
      (price) => price.priceType.name === 'Цена Дзен'
    ).value
    const pricePochatkova = p.assortment.salePrices.find(
      (price) => price.priceType.name === 'Початкова'
    ).value
    if (!priceSale) {
      debugger
      console.log('here')
    }
    return {
      ...p,
      priceSale,
      priceDzen,
      pricePochatkova,
      diffAbs: priceDzen - pricePochatkova,
      diffPerc: (priceDzen - pricePochatkova) / priceDzen,
      quantity: p.quantity,
      totalPochatkova: pricePochatkova * p.quantity,
      totalPriceSale: priceSale * p.quantity,
    }
  })
}

const transformOrder = (order) => {
  if (!order.positions.rows) {
    return order
  }
  const positions = order.positions.rows
  const calcedPositions = calcPositions(positions)

  // what happens when a line item is removed from a list?
  // do they remove it?
  // error on DZEN1-1336 DZEN1-1417
  // this is areal problem, they do not always remove.
  // this needs to be removed from the calculation
  const countPosition = (position) => position.priceSale > 0

  const totalPochatkova = calcedPositions.reduce(
    (prev, curr) => prev + (countPosition(curr) ? curr.totalPochatkova : 0),
    0
  )
  const totalPriceSale = calcedPositions.reduce(
    (prev, curr) => prev + (countPosition(curr) ? curr.totalPriceSale : 0),
    0
  )
  const profitAbs = totalPriceSale - totalPochatkova
  const profitPerc = profitAbs / totalPriceSale

  const profit = {
    totalCost: totalPochatkova,
    totalSale: totalPriceSale,
    profitAbs: profitAbs,
    profitPerc: profitPerc,
  }

  const completedStates = [
    'https://api.moysklad.ru/api/remap/1.2/entity/customerorder/metadata/states/3910b662-a2a3-11ea-0a80-030c000c593f',
    'https://api.moysklad.ru/api/remap/1.2/entity/customerorder/metadata/states/e06a010d-68d4-11ee-0a80-0f2f00083c9b',
    'https://api.moysklad.ru/api/remap/1.2/entity/customerorder/metadata/states/e7e5265e-a2c6-11ec-0a80-07b800242a67',
  ]

  const isCompleted = completedStates.includes(order.state.meta.href)

  const isConfirmed = order.applicable

  return {
    ...order,
    positions: {
      ...order.positions,
      calced: calcedPositions,
    },
    profit,
    isCompleted,
    isConfirmed,
  }
}

class OrderContainer extends Component {
  constructor(props) {
    super(props)
    const currentDate = dayjs() // Get current date with dayjs
    const dateFrom = currentDate.startOf('month') // Set to the first day of the current month

    this.state = {
      dateFrom: dateFrom, // Convert dayjs object to Date for consistency
      dateTo: currentDate, // Current date as Date object
      search: 'DZEN', // Search term initialized as 'DZEN'
    }
  }

  componentDidMount() {
    document.title = 'Маржа Дзен | Liliya'
  }

  onSearch = () => {
    console.log('fetching')
    const { dateFrom, dateTo, search } = this.state
    this.props.fetchOrders({ search, dateFrom, dateTo, withDelta: true })
  }

  getStats = (orders) => {
    if (!orders || orders.length === 0) return {}

    const createStats = (os) => {
      try {
        const orders = os.length
        const revenue = Math.floor(os.reduce((acc, order) => acc + Number(order.sum), 0)) / 100
        const aov = Math.floor(revenue / orders)
        const cogs =
          Math.floor(os.reduce((acc, order) => acc + Number(order.profit.totalCost), 0)) / 100
        const profit =
          Math.floor(os.reduce((acc, order) => acc + Number(order.profit.profitAbs), 0)) / 100
        const avgProfit = Math.floor(profit / orders)
        const margin = Math.floor((profit / revenue) * 10000) / 100

        return { orders, revenue, aov, cogs, profit, avgProfit, margin }
      } catch (err) {
        debugger
        console.error(err)
      }
    }

    const all = createStats(orders)
    const confirmed = createStats(orders.filter((o) => o.isConfirmed))
    const paid = createStats(orders.filter((o) => o.state.name === 'дзен ок'))
    const pending = createStats(
      orders.filter((o) => o.state.name !== 'дзен ок' && o.isConfirmed && !o.isCompleted)
    )
    const completed = createStats(orders.filter((o) => o.isCompleted))

    return { all, confirmed, completed, paid, pending }
  }

  setHalfMonthRange = (monthNumber, half) => {
    // Create fixed date objects for the specified month
    const year = dayjs().year()
    const monthIndex = monthNumber - 1 // Convert to 0-based month index

    // Create a new dayjs object for the first day of the month
    const firstDay = dayjs(new Date(year, monthIndex, 1))

    // Create dates for the first half (1-16)
    const firstHalfStart = firstDay
    const firstHalfEnd = dayjs(new Date(year, monthIndex, 16))

    // Create dates for the second half (17-end of month)
    const secondHalfStart = dayjs(new Date(year, monthIndex, 17))
    // Get the last day of the month by going to the next month and subtracting a day
    const lastDay = dayjs(new Date(year, monthIndex + 1, 0))

    if (half === 'first') {
      this.setState({
        dateFrom: firstHalfStart.startOf('day'),
        dateTo: firstHalfEnd.endOf('day'),
      })
    } else {
      this.setState({
        dateFrom: secondHalfStart.startOf('day'),
        dateTo: lastDay.endOf('day'),
      })
    }
  }

  clearDates = () => {
    const currentDate = dayjs()
    const tenYearsAgo = currentDate.subtract(10, 'year') // Calculate the date 10 years ago
    this.setState({
      dateFrom: tenYearsAgo.startOf('day'), // Reset to the start of the day 10 years ago
      dateTo: currentDate.endOf('day'), // Reset to the end of the current day
    })
  }

  render() {
    const { dateFrom, dateTo, search } = this.state
    const { orders, ordersLoading } = this.props.orders
    const mappedData = orders.map((d) => transformOrder(d))
    const stats = this.getStats(mappedData)
    const nonExpandedOrders = mappedData.filter((o) => !o.positions.rows)
    // const selectedOrder = orders.find(o => o.id === selectedOrderId);
    return (
      <div>
        <div>
          <Title level={3} style={{ marginBottom: 5, marginTop: 0 }}>
            Замовлення дельта
          </Title>
        </div>
        <div style={{ display: 'flex', gap: 20, margin: '10px 0' }}>
          <Button onClick={() => this.onSearch()} loading={ordersLoading} type="primary">
            Search
          </Button>
          <div>
            from:{' '}
            <input
              type="date"
              value={dateFrom.format('YYYY-MM-DD')} // Format date for input
              onChange={(e) => {
                const selectedDate = dayjs(e.target.value).tz('Europe/Kiev').startOf('day')
                this.setState({ dateFrom: selectedDate })
              }}
            />
          </div>
          <div>
            to:{' '}
            <input
              type="date"
              value={dateTo.format('YYYY-MM-DD')} // Format date for input
              onChange={(e) => {
                const selectedDate = dayjs(e.target.value).tz('Europe/Kiev').endOf('day')
                this.setState({ dateTo: selectedDate })
              }}
            />
          </div>
          <div>
            {/* Clear Dates Tag */}
            <Tag color="red" onClick={this.clearDates} style={{ cursor: 'pointer' }}>
              Clear
            </Tag>
            {/* Half-month options */}
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((monthNumber) => (
              <Fragment key={monthNumber}>
                <Tag
                  color="orange"
                  onClick={() => this.setHalfMonthRange(monthNumber, 'first')}
                  style={{ cursor: 'pointer' }}
                >
                  {dayjs()
                    .month(monthNumber - 1)
                    .format('MMM')}{' '}
                  1-16
                </Tag>
                <Tag
                  color="orange"
                  onClick={() => this.setHalfMonthRange(monthNumber, 'second')}
                  style={{ cursor: 'pointer' }}
                >
                  {dayjs()
                    .month(monthNumber - 1)
                    .format('MMM')}{' '}
                  17-30
                </Tag>
              </Fragment>
            ))}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            gap: 5,
            alignItems: 'center',
            marginTop: '10px',
            marginBottom: 10,
          }}
        >
          <div style={{ width: 250, display: 'flex', gap: 5, alignItems: 'center' }}>
            search:
            <Input value={search} onChange={(e) => this.setState({ search: e.target.value })} />
          </div>
          <div>
            <Tag
              color="blue"
              onClick={() => this.setState({ search: 'DZEN' })} // Set search to 'DZEN'
              style={{ cursor: 'pointer' }}
            >
              DZEN
            </Tag>
            <Tag
              color="green"
              onClick={() => this.setState({ search: 'дзен' })} // Set search to 'дзен'
              style={{ cursor: 'pointer' }}
            >
              дзен
            </Tag>
          </div>
        </div>
        {/* // {(orders, revenue, aov, cogs, profit, avgProfit, margin)} */}
        {stats.all && (
          <div style={{ display: 'flex', gap: 30, margin: '10px 0' }}>
            <div style={{ width: 100 }}>Всі:</div>
            <Statistic title="Orders" value={stats.all.orders} />
            <Statistic title="Revenue" value={stats.all.revenue} />
            <Statistic title="Cost of goods" value={stats.all.cogs} />
            <Statistic title="Profit" value={stats.all.profit} />
            <Statistic title="AOV" value={stats.all.aov} />
            <Statistic title="Average profit" value={stats.all.avgProfit} />
            <Statistic title="Margin %" value={stats.all.margin} />
          </div>
        )}
        {stats.confirmed && (
          <div style={{ display: 'flex', gap: 30, margin: '10px 0' }}>
            <div style={{ width: 100 }}>Підтверджені:</div>
            <Statistic title="Orders" value={stats.confirmed.orders} />
            <Statistic title="Revenue" value={stats.confirmed.revenue} />
            <Statistic title="Cost of goods" value={stats.confirmed.cogs} />
            <Statistic title="Profit" value={stats.confirmed.profit} />
            <Statistic title="AOV" value={stats.confirmed.aov} />
            <Statistic title="Average profit" value={stats.confirmed.avgProfit} />
            <Statistic title="Margin %" value={stats.confirmed.margin} />
          </div>
        )}
        {stats.paid && (
          <div style={{ display: 'flex', gap: 30, margin: '10px 0' }}>
            <div style={{ width: 100 }}>Оплачені:</div>
            <Statistic title="Orders" value={stats.paid.orders} />
            <Statistic title="Revenue" value={stats.paid.revenue} />
            <Statistic title="Cost of goods" value={stats.paid.cogs} />
            <Statistic title="Profit" value={stats.paid.profit} />
            <Statistic title="AOV" value={stats.paid.aov} />
            <Statistic title="Average profit" value={stats.paid.avgProfit} />
            <Statistic title="Margin %" value={stats.paid.margin} />
          </div>
        )}
        {stats.pending && (
          <div style={{ display: 'flex', gap: 30, margin: '10px 0' }}>
            <div style={{ width: 100 }}>Чекають:</div>
            <Statistic title="Orders" value={stats.pending.orders} />
            <Statistic title="Revenue" value={stats.pending.revenue} />
            <Statistic title="Cost of goods" value={stats.pending.cogs} />
            <Statistic title="Profit" value={stats.pending.profit} />
            <Statistic title="AOV" value={stats.pending.aov} />
            <Statistic title="Average profit" value={stats.pending.avgProfit} />
            <Statistic title="Margin %" value={stats.pending.margin} />
          </div>
        )}
        {stats.completed && (
          <div style={{ display: 'flex', gap: 30, margin: '10px 0' }}>
            <div style={{ width: 100 }}>Виконані:</div>
            <Statistic title="Orders" value={stats.completed.orders} />
            <Statistic title="Revenue" value={stats.completed.revenue} />
            <Statistic title="Cost of goods" value={stats.completed.cogs} />
            <Statistic title="Profit" value={stats.completed.profit} />
            <Statistic title="AOV" value={stats.completed.aov} />
            <Statistic title="Average profit" value={stats.completed.avgProfit} />
            <Statistic title="Margin %" value={stats.completed.margin} />
          </div>
        )}
        {nonExpandedOrders.length > 0 && <div>Non expanded orders: {nonExpandedOrders.length}</div>}
        <OrdersDeltaTable data={mappedData} isLoading={ordersLoading} />
      </div>
    )
  }
}

const mapStateToProps = (state) => state

const mapDispatchToProps = {
  ...OrdersActions,
  ...SMSActions,
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(OrderContainer)
