import React, { Component } from 'react'
import { Table, Statistic } from 'antd'
import ReactJson from 'react-json-view'
import _ from 'lodash'

class OrdersDeltaTable extends Component {
  getColumns = () => {
    return [
      {
        title: 'Номер Замовлення',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Дата',
        dataIndex: 'moment',
        key: 'moment',
      },
      {
        title: 'Сума',
        dataIndex: 'sum',
        key: 'sum',
        render: (one, two) => two.sum / 100,
      },
      {
        title: 'Profit abs',
        dataIndex: 'printed',
        key: 'profit',
        render: (one, two) => (two.profit ? Math.ceil(two.profit.profitAbs) / 100 : 'N/A'),
      },
      {
        title: 'Profit %',
        dataIndex: 'printed',
        key: 'profit',
        render: (one, two) =>
          two.profit ? `${Math.floor(two.profit.profitPerc * 10000) / 100}%` : 'N/A',
      },
      // {
      //   title: `Імʼя`,
      //   dataIndex: 'description',
      //   key: 'description',
      //   // render: (one, two) => two.agent.full.name || two.agent.full.legalTitle,
      // },
      // {
      //   title: 'Напечатано',
      //   dataIndex: 'printed',
      //   key: 'printed',
      // },
      {
        title: 'Напечатано',
        dataIndex: 'printed',
        key: 'printed',
        filters: [
          { text: 'Так', value: true },
          { text: 'Ні', value: false },
        ],
        onFilter: (value, record) => record.printed === value,
        render: (one, two) => (two.printed ? 'так' : 'ні'),
      },
      {
        title: 'Проведено',
        dataIndex: 'applicable',
        key: 'applicable',
        filters: [
          { text: 'Так', value: true },
          { text: 'Ні', value: false },
        ],
        onFilter: (value, record) => record.applicable === value,
        render: (one, two) => (two.applicable ? 'так' : 'ні'),
      },

      // {
      //     title: 'Отгружено',
      //     dataIndex: 'description',
      //     key: 'description',
      //     render: (one, two) => two.agent.full.phone,
      // },
      // {
      //     title: 'Статус',
      //     dataIndex: 'description',
      //     key: 'description',
      //     render: (one, two) => two.agent.full.legalTitle,
      // },
    ]
  }

  // Function to determine if a row should be highlighted
  isWarningRow = (record) => {
    return (
      record.positions.calced &&
      record.positions.calced.some((position) => position.diffAbs < 0 || position.priceSale <= 0)
    )
  }

  // Function to determine if a position row should be highlighted
  isWarningPositionRow = (position) => {
    return position.diffAbs <= 0 || position.totalPriceSale <= 0
  }

  expandedRowRender = (record) => {
    const profit = record.profit ? (
      <div style={{ display: 'flex', gap: 20 }}>
        <Statistic title="Total Order Price" value={record.profit.totalSale / 100} precision={2} />
        -
        <Statistic title="Total Cost" value={record.profit.totalCost / 100} precision={2} />
        =
        <Statistic title="Profit Absolute" value={record.profit.profitAbs / 100} precision={2} />
        or
        <Statistic
          title="Profit Percentage"
          value={`${Math.floor(record.profit.profitPerc * 10000) / 100}%`}
          precision={2}
        />
      </div>
    ) : null

    const positionsColumns = [
      {
        title: 'Assortment',
        dataIndex: 'assortment.name',
        key: 'assortment',
        render: (all, b) => b.assortment.name,
      },
      {
        title: 'Price Початкова',
        dataIndex: 'pricePochatkova',
        key: 'pricePochatkova',
        render: (text) => (text / 100).toFixed(2),
      },
      {
        title: 'Price Dzen',
        dataIndex: 'priceDzen',
        key: 'priceDzen',
        render: (text) => (text / 100).toFixed(2),
      },
      {
        title: 'Difference Absolute',
        dataIndex: 'diffAbs',
        key: 'diffAbs',
        render: (text) => (text / 100).toFixed(2),
      },
      {
        title: 'Difference Percentage',
        dataIndex: 'diffPerc',
        key: 'diffPerc',
        render: (text) => `${(Math.floor(text * 10000) / 100).toFixed(2)}%`,
      },
      {
        title: 'Sale Price',
        dataIndex: 'priceSale',
        key: 'priceSale',
        render: (text) => (text / 100).toFixed(2),
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Total Sale Price',
        dataIndex: 'totalPriceSale',
        key: 'totalPriceSale',
        render: (text) => (text / 100).toFixed(2),
      },
    ]

    const positions = record.positions.calced ? (
      <div>
        <h4>Positions</h4>
        <Table
          dataSource={record.positions.calced}
          columns={positionsColumns}
          pagination={false}
          rowKey="id"
          size="small"
          rowClassName={(position) => (this.isWarningPositionRow(position) ? 'warning-row' : '')}
        />
      </div>
    ) : null

    return (
      <div style={{ maxWidth: '1200px' }}>
        {profit}
        {positions}
        <ReactJson src={record} collapsed={true} />
      </div>
    )
  }

  render() {
    const { data, isLoading } = this.props
    const tableData = _.sortBy(data, 'date')
    // console.log('about to render table with data', tableData);

    return (
      <Table
        dataSource={tableData}
        columns={this.getColumns()}
        loading={isLoading}
        pagination={{ pageSize: 20, hideOnSinglePage: true }}
        rowKey="id"
        size="small"
        expandedRowRender={this.expandedRowRender}
        rowClassName={(record) => (this.isWarningRow(record) ? 'warning-row' : '')}
      />
    )
  }
}

export default OrdersDeltaTable
