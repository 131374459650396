import axios from 'axios'

const isProd = process.env.NODE_ENV == 'production'

export const getBaseUrl = () => {
  // console.log('ENVIRONMENTS', env);
  if (isProd) return 'https://app.liliya.com.ua/api/'
  return 'http://127.0.0.1:5001/api/'
}

const Instance = axios.create({
  baseURL: getBaseUrl(),
  timeout: 1000000,
})

const getToken = () => localStorage.getItem('userId')

Instance.interceptors.request.use(
  (config) => {
    config.headers['userid'] = getToken()
    // console.log('attached header', getToken());
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export default Instance
