import React, { Component } from 'react'
import { Layout, Menu, Avatar, Badge, Button, Progress } from 'antd'
import {
  BellOutlined,
  DownOutlined,
  HomeOutlined,
  SettingOutlined,
  DollarOutlined,
  LogoutOutlined,
  ReadOutlined,
} from '@ant-design/icons'

import history from '../../history'

import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'

import './Header.css'

import { actions as SettingsActions } from '../../store/SettingsReducer'

import logo from './logo.png'
import dzensad_logo from './dzensad_logo.png'

const { Header } = Layout
const { SubMenu } = Menu

class AccountSettings extends Component {
  renderSignupMessage = () => {
    return [
      <Menu.Item key={'/login'}>
        <Link to={'/login'}>Log In</Link>
      </Menu.Item>,
      <Menu.Item key={'/sign-up'}>
        <Link to={'/sign-up'}>Sign Up For 7 Day Trial</Link>
      </Menu.Item>,
    ]
  }

  renderImpersonate = (monitor) => {
    if (!monitor.isImpersonateMode) return null
    return (
      <Menu.Item key="12" onClick={this.props.monitorImpersonateOff}>
        {/* <Link to={'/app/admin'}> */}
        Impersonating User: {monitor.impersonateUserId}
        {/* </Link> */}
      </Menu.Item>
    )
  }

  renderUserMenu = (userMenuProps) => {
    const { currentUserInitials, planCredits, credits } = userMenuProps
    const MenuTitle = () => (
      <span>
        <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 15 }}>
          {currentUserInitials}
        </Avatar>
        <span>Account</span>
        <DownOutlined style={{ marginLeft: 10 }} />
      </span>
    )
    return (
      <SubMenu key="sub1" title={<MenuTitle />}>
        <Menu.Item key="21" onClick={() => this.props.history.push('/app/dashboard')}>
          <HomeOutlined />
          Dashboard
        </Menu.Item>
        <Menu.Item key="30" onClick={() => this.props.history.push('/app/account/usage/')}>
          <SettingOutlined />
          Settings
        </Menu.Item>
        <Menu.Item key="60" onClick={this.props.signOutUser}>
          <LogoutOutlined />
          Log Out
        </Menu.Item>
      </SubMenu>
    )
  }

  render() {
    const { settings, monitor = {} } = this.props
    const { name, isTrial, permissions, credits, planCredits } = settings
    const currentUserInitials = name ? `${name[0]}` : 'U'
    const isAdmin = permissions.allowedAdmin
    const userMenuProps = {
      currentUserInitials,
      isAdmin,
      credits,
      isTrial,
      planCredits,
    }

    return (
      <Menu
        theme="light"
        mode="horizontal"
        style={{ lineHeight: '64px', border: 'none' }}
        selectable={false}
        className="header-right"
      >
        {this.renderImpersonate(monitor)}
        {this.renderUserMenu(userMenuProps)}
      </Menu>
    )
  }
}

const getActiveId = (menu, path) => {
  return menu.filter((i) => i.url === path).map((i) => i.url)
}

class HeaderComponent extends Component {
  state = { visible: false }

  showModal = () => {
    this.setState({ visible: true })
  }

  handleOk = (e) => {
    console.log(JSON.stringify({ message: e.message, stack: e.stack }))
    this.setState({ visible: false })
  }

  handleCancel = (e) => {
    console.log(JSON.stringify({ message: e.message, stack: e.stack }))
    this.setState({ visible: false })
  }

  showBetaModal = () => {
    console.log('show')
    this.setState({ visible: true })
  }

  render() {
    const { userId, emailVerified, permissions } = this.props.settings
    if (!userId || !emailVerified) return null
    const isAdmin = permissions.allowedAdmin || false
    const userMenu = [
      { title: 'Головна', url: '/app/dashboard', isExternal: false },
      // { title: 'Всі Замовлення', url: '/app/orders', isExternal: false },
      // { title: 'Пошук Замовлення', url: '/app/orders/search', isExternal: false },
      { title: 'Характеристики', url: '/app/product-details', isExternal: false },
      // { title: 'Projects', url: '/app/projects/', isExternal: false },
      // { title: 'Properties', url: '/app/properties/', isExternal: false },
      isAdmin && { title: 'Tools', url: '/app/admin', isExternal: false },
    ].filter((m) => !!m)
    const selectedKeys = getActiveId(userMenu, this.props.history.location.pathname)
    return (
      <div style={{ zIndex: 1 }}>
        <div className="header-container">
          <Header className="header-left" style={{ background: '#fff', padding: 0 }}>
            <div className="logo">
              <img src={dzensad_logo} alt="logo" width="150px" />
            </div>
            <Menu
              selectedKeys={selectedKeys}
              theme="light"
              mode="horizontal"
              style={{ lineHeight: '64px', border: 'none' }}
            >
              {userMenu.map((m, id) => {
                if (m.isExternal)
                  return (
                    <Menu.Item key={m.url}>
                      <span onClick={() => window.open(m.url)}>{m.title}</span>
                    </Menu.Item>
                  )
                return (
                  <Menu.Item key={m.url}>
                    <Link to={m.url}>{m.title}</Link>
                  </Menu.Item>
                )
              })}
            </Menu>
          </Header>
          <AccountSettings {...this.props} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => state

const mapDispatchToProps = {
  ...SettingsActions,
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(HeaderComponent)
